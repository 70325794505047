import { FC, useState } from "react";

type FormFieldStyles = {
  width?: string;
};

const DialogFormField: FC<{
  name: string;
  type: string;
  label?: string;
  placeholder?: string;
  styles?: FormFieldStyles;
  onChange?: (value: string) => void;
}> = ({ name, type, label = "", placeholder = "", styles, onChange = () => {} }) => {
  const [value, setValue] = useState("");
  return (
    <>
      <div className="form-field">
        {label ? <label htmlFor={name}>{label}</label> : null}
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
            onChange(event.target.value);
          }}
        />
      </div>
      <style jsx>
        {`
          .form-field {
            margin: 0 0 16px 0;
          }

          label,
          input {
            display: block;
          }

          label {
            font-size: 0.8rem;
          }

          input {
            border: 1px solid #ccc;
            padding: 6px 12px;
            ${styles?.width ? "width: " + styles.width : null}
          }
        `}
      </style>
    </>
  );
};

export default DialogFormField;
