import { FC, PropsWithChildren } from "react";

const CenteredContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className="centered">
        <div>{children}</div>
      </div>
      <style jsx>{`
        .centered {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100vh;
        }
      `}</style>
    </>
  );
};

export default CenteredContainer;
