import { FC, PropsWithChildren } from "react";

import CenteredContainer from "@components/CenteredContainer";
import DialogTitle from "@components/DialogTitle";

const CenteredDialog: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <CenteredContainer>
        <DialogTitle />
        {children}
      </CenteredContainer>
    </>
  );
};

export default CenteredDialog;
