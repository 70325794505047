import { API_URL, SITE } from "@api/constants";
import { NextSeo } from "next-seo";
import Link from "next/link";
import { useState } from "react";

import CenteredDialog from "@components/CenteredDialog";
import DialogButton from "@components/DialogButton";
import DialogFormField from "@components/DialogFormField";
import DialogWarning from "@components/DialogWarning";

type AuthenticateResult = {
  token: string;
  reason: {
    key: string;
    value: string;
  };
  statusCode: number;
};

const LoginCard = () => {
  const [identity, setIdentity] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const validateAndSubmit = async () => {
    setError("");

    if (!identity.length) {
      setError("Email is required.");
      return;
    }

    if (!password.length) {
      setError("Password is required.");
      return;
    }

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const result = await fetch(`${API_URL}/auth/authenticate`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          site: SITE,
          identity,
          password,
        }),
      });
      if (result.status === 200) {
        const json = (await result.json()) as AuthenticateResult;
        window.location.href = `/token?token=${json.token}`;
      } else if (result.status === 404) {
        const json = (await result.json()) as AuthenticateResult;
        setError(json.reason.value);
      } else {
        setError("An error occurred. Please try again later.");
      }
    } catch (e) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <CenteredDialog>
        <>
          <div className="action">
            {error ? <DialogWarning message={error} /> : null}
            <DialogFormField
              name="username"
              type="email"
              placeholder="Email Address"
              styles={{ width: "100%" }}
              onChange={(value) => setIdentity(value)}
            />
            <DialogFormField
              name="password"
              type="password"
              placeholder="Password"
              styles={{ width: "100%" }}
              onChange={(value) => setPassword(value)}
            />
          </div>
          <div>
            <DialogButton
              onClick={async () => {
                await validateAndSubmit();
              }}>
              Sign In
            </DialogButton>
          </div>
          <div>
            <ul>
              <li>
                <Link href="/forgot-password">
                  <a>Forgot your password?</a>
                </Link>
              </li>
              <li>
                <Link href="/register">
                  <a>Create an account</a>
                </Link>
              </li>
            </ul>
          </div>
        </>
      </CenteredDialog>
      <style jsx>
        {`
          ul {
            list-style-type: none;
            padding: 0;
            font-size: 0.8rem;
          }

          li {
            margin: 8px 0;
          }

          a {
            color: #0066cc;
          }
        `}
      </style>
    </>
  );
};

const LoginPage = () => {
  return (
    <>
      <NextSeo title="Login" />
      <LoginCard />
    </>
  );
};

export default LoginPage;
