import { SITE_NAME } from "@api/constants";

const DialogTitle = () => {
  return (
    <>
      <div className="title">
        <img src="/static/logo.png" alt={SITE_NAME} width={238} />
      </div>
      <style jsx>
        {`
          .title {
            text-align: center;
            margin: 16px 0 32px 0;
          }
        `}
      </style>
    </>
  );
};

export default DialogTitle;
