import { FC } from "react";

const DialogWarning: FC<{ message: string }> = ({ message }) => {
  return (
    <>
      <div className="message">{message}</div>
      <style jsx>
        {`
          .message {
            color: red;
            font-size: 0.9rem;
            margin: 16px 0;
            width: 260px;
          }
        `}
      </style>
    </>
  );
};

export default DialogWarning;
